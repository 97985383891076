import React, { useEffect, useState } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const ModalSample = lazyLoad(() => import("../components/Modals/ModalSample"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const Video = lazyLoad(() => import("../components/Video/Video"))
/**
 * @param tabs - tab content
 * @param product - shopify data for 30DMP
 * */
const ThirtyDayMealPlan = ({ tabs, product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sampleModalType, setSampleModalType] = useState("")

  // Set sample modal data
  const handleSampleModal = type => {
    setIsModalOpen(true)
    setSampleModalType(type)
  }

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isModalOpen])

  // Trigger testimonials
  useEffect(() => {
    testimonials(product)
  }, [])

  /*==========================
  READ MORE
  =========================*/
  const [readMore, setReadMore] = useState(false)
  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="30 Day Challenge Meal Plan | The Betty Rocker"
        title="30 Day Challenge Meal Plan"
        description="Enjoy tasty, delicious and healthy meals (gluten and dairy free) designed to support your body in melting off unwanted fat, and sculpting a toned, fit physique from the inside out!"
        url="https://store.thebettyrocker.com/products/30-day-meal-plan/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/30dch-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero bg--leaves py--30 py--xs-20">
        <div className="container">
          <h2 className="h2--sm h2--sm-xs fw--medium mb--50 mb--sm-20">
            Learn My <span className="txt--pink">Time-Saving Eating Secrets</span> And See A{" "}
            <br className="d-none d-lg-block" />
            <span className="txt--pink">Day Of My Meals</span> Broken Down{" "}
            <span className="d-inline-block">So You Can Copy Me…</span>
          </h2>

          <div className="container container--xs">
            <div className="video bg--white video--16-9 border-5 border-white mb--30 mb--xs-20">
              <Video url="https://player.vimeo.com/video/314907783" />
            </div>
            <div className="txt--center">
              <a className="btn btn--md mb--10 mb--xs-0 fw--medium" onClick={scrollTo} href="#start-now">
                get my plan
              </a>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      {/*food row*/}
      <section className="p--0">
        <img
          loading="lazy"
          src="/images/30dch/food-row.jpg"
          alt=""
          width="1948"
          height="161"
          className="lazy d-none d-md-block radius-0"
        />

        <div className="d-none d-sm-flex justify-content-between d-md-none bg--white row-food">
          <img
            loading="lazy"
            src="/images/30dch/food-row-1.jpg"
            alt=""
            width="377"
            height="94"
            className="lazy no-shadow radius-0"
          />
          <img
            loading="lazy"
            src="/images/30dch/food-row-2.jpg"
            alt=""
            width="377"
            height="94"
            className="lazy no-shadow radius-0"
          />
        </div>
        <div className="d-block d-sm-none txt--center bg--white">
          <img
            loading="lazy"
            src="/images/30dch/food-row-3.jpg"
            alt=""
            width="560"
            height="287"
            className="lazy no-shadow radius-0"
          />
        </div>
      </section>

      {/*testimonials #1*/}
      <section id="results" className="arrow-down js-section">
        <div className="container container--md">
          <h3 className="txt--center mb--40 txt--capitalize mb--xs-20 h3--mob">
            <strong>
              Combine The 30 Day Meal Plan With <br className="d-none d-lg-block" />
              Your Workouts For Great Results!
            </strong>
          </h3>

          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/30dch/testimonial-1.jpg?v=1.0.1"
                    alt="Testimonial of Melina O."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">“I’m feeling so much better!”</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      "Hi! I just finished the 30 day challenge. This is my before after pictures. I share them so u can
                      see that it really work. I didnt lost a lot of weight but u can see that im smaller so go for
                      it!!! And thank you betty rocker you rock!! im feeling so much better !!!"
                    </p>
                    <p className="txt--pink">
                      <strong>- Melina O.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/30dch/testimonial-2.jpg"
                    alt="Testimonial of Heather M."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">“I love my abs!”</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>"I can't believe the 30 days went by so fast! I love my abs!"</p>
                    <p className="txt--pink">
                      <strong>- Heather M.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/30dch/testimonial-3.jpg"
                    alt="Testimonial of Leanne S."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">
                      “I was really excited when I put these two photos next to each other!”
                    </h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      "Your 30 day program popped up on my Facebook and I decided to give it a go. I really didn't
                      expect to see much of a difference, so I was really excited when I put these two photos next to
                      each other! So to anyone out there that didn't take photos, really don't just believe what the
                      numbers say! They really don't give you the full picture! Thanks again Betty!"
                    </p>
                    <p className="txt--pink">
                      <strong>- Leanne S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/30dch/testimonial-4.jpg?v=1.0.1"
                    alt="Testimonial of Alesha J."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">“I love all the support”</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      "Thank you Betty! I love how the weeks get harder as we go! I love all the support and how we do
                      this together!"
                    </p>
                    <p className="txt--pink">
                      <strong>- Alesha J.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/30dch/testimonial-5.jpg?v=1.0.1"
                    alt="Testimonial of Lani S."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">“Rock on Betties!”</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>"My before and afters! Rock on Betties!"</p>
                    <p className="txt--pink">
                      <strong>- Lani S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--10 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/30dch/testimonial-6.jpg?v=1.0.1"
                    alt="Testimonial of Karen G."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">“I so love the program!”</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>"Thank you!! So love the program!! I am including my progress pics!"</p>
                    <p className="txt--pink">
                      <strong>- Karen G.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--section arrow-down">
        <div className="container container--md mb--40">
          <h3 className="mb--30 h3--mob">
            <strong>Use This Healthy Eating System For a LIFETIME of Success!</strong>
          </h3>

          <img
            loading="lazy"
            src="/images/30dch/30dch-books.png"
            alt=""
            width="1056"
            height="432"
            className="lazy no-shadow mb--20"
          />

          <ul className="pl--40 pl--md-0">
            <li>
              Get set up for a lifetime of success, and{" "}
              <strong>enjoy eating in a way that supports your active lifestyle</strong> – in a convenient, time-saving
              format that’s fun!
            </li>
            <li>
              Enjoy delicious, healthy, whole foods and gluten free, dairy free recipes. Get fueled with what your body
              craves to give you <strong>lasting energy to power through your workouts.</strong>
            </li>
            <li>
              Give yourself the right balance of nutrients{" "}
              <strong>
                so your body burns extra fat it’s carrying and supports the lean muscle you’re sculpting with the
                workouts you’re doing.
              </strong>
            </li>
            <li>
              With meals and recipes that are quick and easy to prepare,{" "}
              <strong>
                you won’t need tons of ingredients. Vegetarian options are included too, so you’ll have plenty of
                variety.
              </strong>
            </li>
            <li>
              Enjoy food that NOURISHES and satisfies you – and does the job that food is supposed to do, which is to{" "}
              <strong>sculpt your body from the INSIDE.</strong>
            </li>
            <li>
              This program is the greatest guide I could ever imagine giving a person that is ready and committed to
              creating a body they love…{" "}
              <strong>and it’s the ultimate key to my own personal “nutritional toolbox.”</strong>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-3 mb--30 mb--sm-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  src="/images/30dch/Week-1.jpg"
                  className="shadow-0 lazy"
                  alt=""
                  width="370"
                  height="400"
                />
                <div className="img-card__title txt--uppercase">Week 1</div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb--30 mb--sm-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  src="/images/30dch/Week-2.jpg"
                  className="shadow-0 lazy"
                  alt=""
                  width="370"
                  height="400"
                />
                <div className="img-card__title txt--uppercase">Week 2</div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb--30 mb--sm-15 mb--xs-0">
              <div className="img-card">
                <img
                  loading="lazy"
                  src="/images/30dch/Week-3.jpg"
                  className="shadow-0 lazy"
                  alt=""
                  width="370"
                  height="400"
                />
                <div className="img-card__title txt--uppercase">Week 3</div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb--30 mb--sm-15 mb--xs-0">
              <div className="img-card">
                <img
                  loading="lazy"
                  src="/images/30dch/Week-4.jpg"
                  className="shadow-0 lazy"
                  alt=""
                  width="370"
                  height="400"
                />
                <div className="img-card__title txt--uppercase">Week 4</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*fb testimonials #1*/}
      <section className="arrow-down">
        <div className="container">
          <div className="row align-items-center mt--30 mt--xs-0 img--stack-wrapper">
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/fb-testimonial-1.jpg"
                  alt=""
                  width="547"
                  height="222"
                />
              </div>
            </div>
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/fb-testimonial-2.jpg"
                  alt=""
                  width="488"
                  height="191"
                />
              </div>
            </div>
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/fb-testimonial-3.jpg"
                  alt=""
                  width="547"
                  height="184"
                />
              </div>
            </div>
            <div className="col-md-6 mb--50 mb--xs-20">
              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/fb-testimonial-4.jpg"
                  alt=""
                  width="490"
                  height="126"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*what's included*/}
      <section id="included" className="bg--section arrow-down js-section">
        <div className="container container--md">
          <h3 className="mb--20 h3--mob mb--xs-10">
            <strong>
              The 30-Day Eating Guide And Meal Plan Is Available Right Now, With Instant Access — So You Can Start
              Immediately.
            </strong>
          </h3>

          <h3 className="txt--pink mb--40 mb--xs-20">
            <strong>Here’s What’s Included:</strong>
          </h3>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#1</div>
              <h4>
                <strong>
                  <span className="txt--pink">INCLUDED:</span> Your Healthy Eating Guide
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row gutters-7_5 align-items-center">
                <div className="col-md-6 mb--sm-30 txt--center">
                  <img
                    loading="lazy"
                    className="shadow-0 mw--md-none lazy"
                    src="/images/30dch/what-you-get-1.png"
                    alt=""
                    width="302"
                    height="366"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      The perfect foundation for healthy eating to support you for a{" "}
                      <strong>lifetime in your strong, fit body.</strong>
                    </li>
                    <li>
                      The 101 on the exact foods you need to eat to <strong>reach your goals,</strong> balance your
                      energy, banish unwanted body fat and get strong and lean.
                    </li>
                  </ul>
                  <button
                    onClick={() => handleSampleModal("Modal 1")}
                    className="btn btn--md btn--md-sm ml--40 mx--sm-auto"
                  >
                    see sample pages
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#2</div>
              <h4>
                <strong>
                  <span className="txt--pink">INCLUDED:</span> Delicious, Easy Healthy Recipes
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="shadow-0 lazy"
                    src="/images/30dch/what-you-get-2.png"
                    alt=""
                    width="472"
                    height="410"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <strong>Easy-to-make, delicious recipes</strong>
                    </li>
                    <li>
                      <strong>Gluten free, dairy free and vegetarian</strong> options
                    </li>
                    <li>
                      Simple, <strong>whole food</strong> ingredients
                    </li>
                    <li>
                      Delicious <strong>breakfasts</strong>
                    </li>
                    <li>
                      Easy <strong>snacks</strong> and <strong>delicious smoothies</strong>
                    </li>
                    <li>
                      Quick and healthy <strong>lunches and dinners</strong>
                    </li>
                  </ul>

                  <button
                    onClick={() => handleSampleModal("Modal 2")}
                    className="btn btn--md btn--md-sm ml--40 mx--sm-auto"
                  >
                    see sample pages
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#3</div>
              <h4>
                <strong>
                  <span className="txt--pink">INCLUDED:</span> Time-Saving Daily Meal Plans
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="shadow-0 lazy"
                    src="/images/30dch/what-you-get-3.png"
                    alt=""
                    width="471"
                    height="411"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <strong>4 Weeks of Daily Menus</strong> combining the{" "}
                      <strong>right foods at the right times</strong> with your workouts
                    </li>
                    <li>
                      <strong>Easy-to-adjust menus</strong> (including Vegetarian Options)
                    </li>
                    <li>
                      <strong>Use my easy eating system</strong> for a lifetime of success
                    </li>
                    <li>
                      Master an eating style that <strong>works for your LIFE, not just for a week.</strong>
                    </li>
                  </ul>
                  <button
                    onClick={() => handleSampleModal("Modal 3")}
                    className="btn btn--md btn--md-sm ml--40 mx--sm-auto"
                  >
                    see sample pages
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#4</div>
              <h4>
                <strong>
                  <span className="txt--pink">INCLUDED:</span> Start Right Away, From Beginner To Advanced
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="shadow-0 lazy"
                    src="/images/30dch/what-you-get-4.png"
                    alt=""
                    width="436"
                    height="436"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      A beginner’s guide for each week to help you jump in and get the most out of the plan and options
                      whether you’re <strong>new to cooking or are just short on time.</strong>
                    </li>
                    <li>
                      <strong>A batch prep guide</strong> for each week that puts the best recipes together for{" "}
                      <strong>variety, ease of set up and time-saving meals</strong> that are ready to grab and go!
                    </li>
                    <li>
                      <strong>A hybrid guide</strong> that shows you exactly which staple recipes to make, while still
                      taking some shortcuts for weeks when this is the best option for you.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#5</div>
              <h4>
                <strong>
                  <span className="txt--pink">INCLUDED:</span> No-Brainer Grocery Lists
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="shadow-0 lazy"
                    src="/images/30dch/what-you-get-5.png"
                    alt=""
                    width="430"
                    height="430"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <strong>Simple Weekly Shopping Lists</strong> to make your life easy
                    </li>
                    <li>
                      <strong>Never overbuy or waste ingredients</strong> with the exact amounts you’ll need each week
                    </li>
                    <li>
                      <strong>Speed through the store with ease</strong>
                    </li>
                  </ul>

                  <button
                    onClick={() => handleSampleModal("Modal 4")}
                    className="btn btn--md btn--md-sm ml--40 mx--sm-auto"
                  >
                    take a look inside
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*bonus*/}
      <section className="arrow-down arrow-down--gray bg--gray-gradient">
        <div className="container">
          <h2 className="h2--sm mb--40 lh--xs-1_3 mb--xs-30">
            <strong>
              Grab Your Meal Plan Today And Enjoy These <span className="d-block txt--pink">Special Bonus Gifts</span>
            </strong>
          </h2>

          <div className="row">
            <div className="col-12 col-md-4 mb--20">
              <div className="card card--height card--border">
                <div className="tag tag--pink tag--xs">#1</div>
                <div className="card__content">
                  <div className="border-bottom-1 border-light-gray txt--center mb--20">
                    <img
                      loading="lazy"
                      src="/images/30dch/book-1.jpg"
                      alt=""
                      width="200"
                      height="234"
                      className="lazy no-shadow mb--20"
                    />
                  </div>
                  <h4 className="fw--medium mb--20">
                    Food Swaps and <br className="d-none d-md-block" />
                    Substitutions Guide
                  </h4>
                  <p>
                    Easily swap different foods into your mean plan, catering to your taste preferences while providing
                    variety and flexibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb--20">
              <div className="card card--height card--border">
                <div className="tag tag--pink tag--xs">#2</div>
                <div className="card__content">
                  <div className="border-bottom-1 border-light-gray txt--center mb--20">
                    <img
                      loading="lazy"
                      src="/images/30dch/book-2.jpg"
                      alt=""
                      width="200"
                      height="234"
                      className="lazy no-shadow mb--20"
                    />
                  </div>
                  <h4 className="fw--medium mb--20">
                    30-Day Challenge <br className="d-none d-md-block" />
                    Recipe Guide
                  </h4>
                  <p>
                    All of the whole food, gluten and dairy free recipes in one place (vegetarian options too) – so you
                    can easily pick and choose recipes to try for you and your family.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb--20 mb--xs-0">
              <div className="card card--height card--border">
                <div className="tag tag--pink tag--xs">#3</div>
                <div className="card__content">
                  <div className="border-bottom-1 border-light-gray txt--center mb--20">
                    <img
                      loading="lazy"
                      src="/images/30dch/book-3.jpg"
                      alt=""
                      width="200"
                      height="234"
                      className="lazy no-shadow mb--20"
                    />
                  </div>
                  <h4 className="fw--medium mb--20">Healthy Desserts</h4>
                  <p>
                    From healthy spins on traditional desserts to recipes that sneak a little extra protein into sweet
                    treats, these delicious recipes are gluten free, dairy free, kid, picky eater, spouse and partner
                    approved!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*testimonials #2*/}
      <section className="arrow-down container container--md">
        <h3 className="mb--40 h3--mob mb--xs-20">
          <strong>Eating Right And Exercise Go Hand In Hand!</strong>
        </h3>

        <div className="row">
          <div className="col-12 col-sm-6 mb--30 mb--xs-20">
            <div className="testimonial testimonial--shadow">
              <div className="testimonial__img">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/testimonial-7.jpg"
                  alt="Testimonial of Jess J."
                  width="432"
                  height="432"
                />
              </div>
              <div className="testimonial__content">
                <div className="testimonial__header js-testimonial-toggle">
                  <h3 className="testimonial__title">“Healthier body, mind and lifestyle!”</h3>
                </div>
                <div className="testimonial__toggeable">
                  <p>"Thank you Bree for helping me Betty Rock my way to a healthier body, mind and lifestyle!"</p>
                  <p className="txt--pink">
                    <strong>- Jess J.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb--30 mb--xs-20">
            <div className="testimonial testimonial--shadow">
              <div className="testimonial__img">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/testimonial-8.jpg"
                  alt="Testimonial of Brianna V."
                  width="432"
                  height="432"
                />
              </div>
              <div className="testimonial__content">
                <div className="testimonial__header js-testimonial-toggle">
                  <h3 className="testimonial__title">“I love seeing and feeling the changes!”</h3>
                </div>
                <div className="testimonial__toggeable">
                  <p>
                    "Well since today is the last day this is my progress picture. I’m not done with week 4 until
                    Friday, but BAM! I love seeing and feeling the changes."
                  </p>
                  <p className="txt--pink">
                    <strong>- Brianna V.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb--30 mb--xs-20">
            <div className="testimonial testimonial--shadow">
              <div className="testimonial__img">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/testimonial-9.jpg"
                  alt="Testimonial of Amanda N."
                  width="432"
                  height="432"
                />
              </div>
              <div className="testimonial__content">
                <div className="testimonial__header js-testimonial-toggle">
                  <h3 className="testimonial__title">“What a difference 30 days can make!”</h3>
                </div>
                <div className="testimonial__toggeable">
                  <p>
                    "Wow! What a difference 30 days can make! "Thanks Betty Rocker. Big gains for me with your 30 day
                    challenge and eating healthy. Your videos are so great. I would wake up everyday excited to see what
                    you had in store for me that day!!"
                  </p>
                  <p className="txt--pink">
                    <strong>- Amanda N.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb--30 mb--xs-20">
            <div className="testimonial testimonial--shadow">
              <div className="testimonial__img">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/testimonial-10.jpg"
                  alt="Testimonial of Alana B."
                  width="432"
                  height="432"
                />
              </div>
              <div className="testimonial__content">
                <div className="testimonial__header js-testimonial-toggle">
                  <h3 className="testimonial__title">“Thanks for this great program!”</h3>
                </div>
                <div className="testimonial__toggeable">
                  <p>
                    "So I’ve been feeling really good these past 2 weeks but wasn’t convinced it was translating to the
                    outside - until I do my comparison pics - wow! Definitely the reinforcement I need to stay committed
                    for the next 2 weeks and beyond! Thanks for this great program and all the supportive people
                    participating!"
                  </p>
                  <p className="txt--pink">
                    <strong>- Alana B.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb--30 mb--xs-20">
            <div className="testimonial testimonial--shadow">
              <div className="testimonial__img">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/testimonial-11.jpg"
                  alt="Testimonial of Michelle."
                  width="432"
                  height="432"
                />
              </div>
              <div className="testimonial__content">
                <div className="testimonial__header js-testimonial-toggle">
                  <h3 className="testimonial__title">“Stronger, healthier and happier!”</h3>
                </div>
                <div className="testimonial__toggeable">
                  <p>
                    "I thank you for helping me kick off the first month of my new life. I thank you for challenging me
                    to be the best possible me that I can, the me that I deserve. I know I'm not where I'd like to be
                    yet, but I'm one month closer than I was! I'm motivated, stronger, healthier, and happier than I
                    ever have been.. and now I'm addicted to this feeling."
                  </p>
                  <p className="txt--pink">
                    <strong>- Michelle</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb--10 mb--xs-0">
            <div className="testimonial testimonial--shadow">
              <div className="testimonial__img">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/30dch/testimonial-12.jpg?v=1.0.1"
                  alt="Testimonial of Renae T."
                  width="432"
                  height="432"
                />
              </div>
              <div className="testimonial__content">
                <div className="testimonial__header js-testimonial-toggle">
                  <h3 className="testimonial__title">“I feel better, healthier and stronger!”</h3>
                </div>
                <div className="testimonial__toggeable">
                  <p>
                    "I weigh the same as when I started, however I feel better, healthier and stronger. I think my abs
                    have more definition than they did before and my butt lifted. I felt like quitting so many times,
                    but I didn't stop."
                  </p>
                  <p className="txt--pink">
                    <strong>- Renae T.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="coaches" className="bg--section arrow-down js-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="mx--auto mb--40 img--round border-5 border-pink mb--15 mw--sm-md">
                <img
                  loading="lazy"
                  className="shadow-0 img--round radius- border-15 border--md-8 border-white"
                  src="/images/coaches/betty-round.jpg"
                  alt=""
                  width="370"
                  height="370"
                />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <h2 className="h2--sm mb--30 txt--left mb--xs-20">
                Meet Your <span className="txt--pink">Coach!</span>
              </h2>

              <p className="fw--black">
                <span className="txt--pink">Who is Betty Rocker?</span> Betty Rocker is me! My name is Bree Argetsinger,
                but I was called “punk rock Betty Crocker” since I was a little girl - which morphed into Betty Rocker!
                I’m a certified trainer, nutrition specialist and motivator of personal growth, and I support women all
                over the world of all ages as they transform inside and out.
              </p>

              <p>
                My passion for fitness and health developed after treating my body very badly for many years and
                choosing to educate myself and do the work to develop good practices based on science, my own intuition
                and a deep love and compassion for other people who were struggling as well.
              </p>
              {readMore && (
                <div>
                  <p>
                    I have mastered many aspects of health and personal development, including becoming a C.H.E.K.
                    (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A.
                    certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner
                    (realignment of the body through manual therapy). I have pursued continuing education to incorporate
                    training with your cycle, training in perimenopause and training post menopause in my programs.
                  </p>
                  <p>
                    My goal is to empower you with the skills and knowledge you need to make the best choices for
                    yourself inside and out – so you can love the person you are, achieve the results you’re looking
                    for, and enjoy yourself along the way.
                  </p>

                  <span className="clearfix" />
                </div>
              )}
              <button
                className={`link link--pink link--read-more ${readMore ? "active" : ""}`}
                onClick={() => setReadMore(!readMore)}
              >
                Read {readMore ? "Less" : "More"}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/*fb testimonials #2*/}
      <section className="container arrow-down">
        <div className="row align-items-center mt--30 mt--xs-0 img--stack-wrapper">
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/30dch/fb-testimonial-5.jpg"
                alt=""
                width="347"
                height="220"
              />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/30dch/fb-testimonial-6.jpg"
                alt=""
                width="485"
                height="231"
              />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/30dch/fb-testimonial-7.jpg"
                alt=""
                width="541"
                height="334"
              />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-20">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/30dch/fb-testimonial-8.jpg"
                alt=""
                width="541"
                height="275"
              />
            </div>
          </div>
        </div>
      </section>

      {/*easy to get started*/}
      <section className="bg--inclined-full">
        <div className="container">
          <h2 className="h2--sm mb--20">It’s So Easy To Get Started…</h2>
          <h3 className="txt--pink h2--mob mb--40">
            Combine the Eating Plan with your workouts for results that last!
          </h3>

          <div className="arrow-separator arrow-separator--down">
            <img
              loading="lazy"
              src="/images/30dch/30-day-promo-1.png"
              alt=""
              className="lazy no-shadow mb--40"
              width="1117"
              height="541"
            />
          </div>
        </div>
      </section>

      {/*including*/}
      <section className="container arrow-down">
        <h3 className="mb--40 h3--mob mb--xs-20">
          <strong>Including…</strong>
        </h3>

        <div className="row">
          <div className="col-12 col-md-6">
            <ul>
              <li>
                Make <strong>eating healthy delicious, fun and EASY…</strong> even if you’re not an experienced cook
              </li>
              <li>
                Discover <strong>time-saving eating tricks for healthy eating,</strong> while{" "}
                <strong>avoiding sabotaging cravings</strong>
              </li>
              <li>
                <strong>Eat real food…</strong> NOT another magic pill, energy drink or diet fad
              </li>
              <li>
                <strong>70+ Simple yet Amazing recipes</strong> to help you{" "}
                <strong>feel and look your best with any workout program</strong>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul>
              <li>
                <strong>Pack your fridge full of healthy options</strong> setting you up for the week and freeing you up
                for your workouts
              </li>
              <li>
                <strong>Feel-good indulgences with the Bonus Desserts Guide</strong>
              </li>
              <li>
                <strong>Food Swaps and Substitutions Guide</strong> for food allergies and dietary restrictions
              </li>
              <li>
                <strong>Available in downloadable format</strong> so <strong>you can print it out,</strong> and/or{" "}
                <strong>enjoy instantly on any device</strong>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/*price-box*/}
      <section id="start-now" className="bg--section arrow-down js-section">
        <div className="container container--md">
          <DigitalProductBuyBox shopifyProduct={product} singleBox={true} />

          <img
            loading="lazy"
            className="shadow-0 d-block mx--auto mb--30 lazy"
            src="/images/credit-cards.png"
            alt=""
            width="300"
            height="39"
          />
          <p className="txt--center txt--14 txt--xs-16 mb--10 mb--xs-20">
            <strong className="fw--bold">For security purposes, all orders are processed on a secure server.</strong>
          </p>
          <p className="txt--14 txt--gray txt--center">
            <i>
              You’ll receive INSTANT ACCESS to the 30-Day Meal Plan and Bonuses immediately
              <br className="d-none d-md-block" />
              after purchase. This program is 100% digital. No physical products will be shipped.
            </i>
          </p>
        </div>
      </section>

      {/*guarantee*/}
      <section className="container">
        <div className="box-guarantee box-guarantee--lg">
          <img
            loading="lazy"
            src="/images/guarantee-30.png"
            alt=""
            width="239"
            height="245"
            className="lazy no-shadow"
          />
          <div className="row">
            <div className="col-lg-8">
              <h3 className="txt--white mb--20 txt--smd-left">
                <strong>No Risk 30-Day Meal Plan Money Back GUARANTEE</strong>
              </h3>
              <h5 className="txt--white mb--20 txt--xs-18">
                Go through the entire 30-Day Meal Plan and <br className="d-none d-lg-block" /> experience the
                difference for yourself…
              </h5>

              <p className="txt--18 txt--white">
                I know that this information and this Meal Plan will have an incredibly positive impact on your life,
                because not only do I live it, I see it, and I read about it from the people who use it over and over.
              </p>

              <p className="txt--18 txt--white">
                But I know you have already been on a journey getting here, and I trust that you know what’s best for
                yourself. At the end of the day, if this isn’t the right fit for you, or doesn’t create change in your
                life, don’t sweat it. You can return it within 30 days and get your money back, no questions asked.
              </p>

              <p className="txt--white txt--22 fw--black m--0">
                <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="bg--betty-gradient py--60 py--xs-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h2 className="h2--sm mb--40 lh--xs-1_3 mb--xs-20 txt--sm-left">
                WARNING: <span className="txt--pink">Time is Ticking!</span>
              </h2>

              <p>
                I want to see you get results for the valuable time you spend. What you do today will pay off tomorrow,
                and for years to come.
              </p>
              <p>
                Working out is only PART of the equation. <strong>If you’re not fueling your body properly,</strong>{" "}
                your muscle tissue can’t strengthen, and your energy levels will drop. That makes it hard for your
                metabolism to work efficiently and slows fat loss.
              </p>
              <p>
                You have <strong>everything you need to set yourself up for lasting success</strong> with this program.
              </p>
              <p>
                Don’t put off til tomorrow what can be done today! <strong>Take action now.</strong>
              </p>
              <p>
                <strong>COMBINE</strong> the power of your workouts with eating the right foods using{" "}
                <strong>THIS GUIDE,</strong> and make time serve you!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*faq*/}
      <section className="bg--section js-section py--50 pb--xs-30 pt--xs-30" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-20 lh--xs-1_3">
            Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs productName="30dmp" />
        </div>
      </section>

      {isModalOpen && <ModalSample handleCloseModal={() => setIsModalOpen(false)} type={sampleModalType} />}
    </>
  )
}
export default ThirtyDayMealPlan
